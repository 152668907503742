import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import { CalendarDefaultTheme } from "@uselessdev/datepicker";

import "@fontsource/dm-sans";
import "@fontsource/petrona";


const theme = {
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
  colors: {
    primary: {
      50: '#F94E1911',
      100: '#F94E1926',
      500: '#F94E19FF',
      900: '#F94E19FF',
    },
    accent: {
      500: '#252C52',
      900: '#252C52',
    },
    background: {
      cloudburst: '#252C52',
      linkwater: '#EEF3FA',
      linen: '#FBF7F1',
    },
    white: '#FFFFFF',
  },
  components: {
    Calendar: {
      parts: ['calendar'],
    
      baseStyle: {
        calendar: {
          border: 'none',
          rounded: 'none',
          shadow: 'none',
        },
      },
    },
    CalendarControl: {
      parts: ['button'],
    
      baseStyle: {
        button: {
          h: 6,
          px: 2,
          rounded: 'none',
          fontSize: 'sm',
          color: 'white',
          bgColor: 'accent.900',
    
          _hover: {
            opacity: '0.6',
          },
        },
      },
    },
    CalendarDay: {
      baseStyle: {
        _focus: {
          outline: 'none',
          boxShadow: 'none',
        },
      },
      variants: {
        selected: {
          bgColor: 'accent.900',
    
          _hover: {
            bgColor: 'accent.900',
            opacity: '0.6',
          },
        },
      },
    },
    Steps,
  },
  fonts: {
    default: 'DM Sans',
    heading: 'Petrona',
    body: 'DM Sans',
  },
  styles: {
    global: {
      '.markdown': {
        h1: {
          margin: "32px 0",
          fontSize: "48px",
          fontWeight: 400,
          fontFamily: "Petrona",
          lineHeight: "54px",
        },
        h2: {
          margin: "32px 0",
          fontSize: "48px",
          fontWeight: 400,
          fontFamily: "Petrona",
          lineHeight: "54px",
        },
        h3: {
          margin: "32px 0",
          fontSize: "28px",
          fontWeight: 400,
          fontFamily: "Petrona",
          lineHeight: "33px",
        },
        h4: {
          margin: "32px 0",
          fontSize: "24px",
          fontWeight: 400,
          fontFamily: "Petrona",
          lineHeight: "30px",
        },
        h5: {
          margin: "32px 0",
          fontSize: "20px",
          fontWeight: 400,
          fontFamily: "Petrona",
          lineHeight: "28px",
        },
        h6: {
          margin: "32px 0",
          fontSize: "16px",
          fontWeight: 400,
          fontFamily: "Petrona",
          lineHeight: "24px",
        },
        ul: {
          listStylePosition: "inside",
          margin: "32px 0",
        },
        ol: {
          listStylePosition: "inside",
          margin: "32px 0",
        },
        li: {},
        p: {
          margin: "32px 0",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "21px",
        },
        a: {
          cursor: "pointer",
          textDecoration: "underline",
          outline: "2px solid transparent",
          outlineOffset: "2px",
          color: "#252C52",
          ':hover': {
            opacity: "0.6"
          },
          ':focus': {
            boxShadow: "rgba(66, 153, 225, 0.6)"
          }
        }
      },
      '.description': {
        ul: {
          listStylePosition: "inside",
          margin: "16px 0",
        },
        ol: {
          listStylePosition: "inside",
          margin: "16px 0",
        },
        li: {},
        p: {
          margin: "16px 0",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "21px",
        },
        a: {
          cursor: "pointer",
          textDecoration: "underline",
          outline: "2px solid transparent",
          outlineOffset: "2px",
          color: "#252C52",
          ':hover': {
            opacity: "0.6"
          },
          ':focus': {
            boxShadow: "rgba(66, 153, 225, 0.6)"
          }
        }
      }
    }
  }
};


export default extendTheme(CalendarDefaultTheme, theme);
